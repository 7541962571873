import polarionprofile from "../assets/images/polarionprofile.png";

function Ad() {
  return (
    <section className="text-white">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 mb-20">
        <div className="max-w-xl mx-auto text-center">
          {/* <p className="text-sm font-semibold tracking-widest text-blue-600 uppercase">
          Advanced Consulting and Custom Solutions for Seamless Siemens Polarion Integration
          </p> */}

          <h2 className="mt-6 text-3xl font-bold leading-tight  sm:text-4xl lg:text-4xl ">
            Advanced Consulting and Custom Solutions for Seamless Workflows
          </h2>
        </div>

        <div className="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
          <div className="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
            <div className="flex items-start">
              <svg
                className="flex-shrink-0 text-red-500 w-9 h-9"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* <!-- Top Rectangle (Waterfall/Layer) --> */}
                <rect
                  x="3"
                  y="3"
                  width="8"
                  height="4"
                  rx="1"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M7 7v2.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />

                {/* <!-- Middle Rectangle (Agile/Sprint) --> */}
                <rect
                  x="3"
                  y="12"
                  width="8"
                  height="4"
                  rx="1"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M7 16v2.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />

                {/* <!-- Bottom Rectangle (Kanban/Board) --> */}
                <rect
                  x="13"
                  y="7"
                  width="8"
                  height="4"
                  rx="1"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M17 11v5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />

                {/* <!-- Arrow/Flow --> */}
                <path
                  d="M11 5h2M11 14h2M15 9h2"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>

              <div className="ml-5">
                <h3 className="text-xl font-semibold ">
                  Agile, Waterfall and Hybrid
                </h3>
                <p className="mt-3 text-base text-cyan-200">
                  Streamline development processes across Agile, Waterfall, and
                  hybrid development operations.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <svg
                className="flex-shrink-0 text-blue-600 w-9 h-9"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <div className="ml-5">
                <h3 className="text-xl font-semibold ">
                  Plugin Development & Scripts
                </h3>
                <p className="mt-3 text-base text-cyan-200">
                  Develop your own plugins & run the workflows associated with
                  custom scripts
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <svg
                className="flex-shrink-0 text-red-500 w-9 h-9"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* <!-- First person (center) --> */}
                <circle
                  cx="12"
                  cy="8"
                  r="3"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M15 16v2c0 1.104-.896 2-2 2H11c-1.104 0-2-.896-2-2v-2c0-1.104.896-2 2-2h2c1.104 0 2 .896 2 2z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />

                {/* <!-- Second person (left) --> */}
                <circle
                  cx="7"
                  cy="9"
                  r="2.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M8.5 14v1.5c0 .828-.672 1.5-1.5 1.5H6c-.828 0-1.5-.672-1.5-1.5V14c0-.828.672-1.5 1.5-1.5h1c.828 0 1.5.672 1.5 1.5z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />

                {/* <!-- Third person (right) --> */}
                <circle
                  cx="17"
                  cy="9"
                  r="2.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M18.5 14v1.5c0 .828-.672 1.5-1.5 1.5h-1c-.828 0-1.5-.672-1.5-1.5V14c0-.828.672-1.5 1.5-1.5h1c.828 0 1.5.672 1.5 1.5z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>

              <div className="ml-5">
                <h3 className="text-xl font-semibold ">
                  Collaboration & Compliance
                </h3>
                <p className="mt-3 text-base text-cyan-200">
                  Align and collaborate around a common purpose and objective.
                  Track and measure progress and compliance across project
                  lifecycles.
                </p>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3">
            <img
              className="w-full rounded-lg shadow-xl"
              src={polarionprofile}
              alt=""
            />
            {/* <img
              className="w-full rounded-lg shadow-xl"
              src="https://cdn.rareblocks.xyz/collection/celebration/images/features/7/dashboard-screenshot.png"
              alt=""
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ad;
