function Wrapper({ children }) {
  return (
    // <div className="bg-gradient-to-b from-green-50 to-gray-400 ">
    <div className="bg-gradient-to-b relative ">
      
      {children}
    </div>
  );
}

export default Wrapper;
