import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Terms and Conditions</h1>

      <p className="mb-4">
        Welcome to Corbinsoft! These Terms and Conditions govern your use of our website located at 
        <a href="/home" className="text-blue-500 underline"> www.corbinsoft.com</a>. By accessing or using our website, you 
        agree to be bound by these Terms. If you do not agree with these Terms, please do not use our website.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Use of Our Website</h2>
        <p className="mb-2">
          You agree to use our website only for lawful purposes and in a way that does not infringe the rights of, 
          restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing 
          or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or 
          disrupting the normal flow of dialogue within our website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">2. Intellectual Property Rights</h2>
        <p className="mb-2">
          All content on this website, including but not limited to text, graphics, logos, images, and software, is the 
          property of Corbinsoft or its licensors. You may not reproduce, distribute, or create derivative works from any 
          content on this website without our prior written permission.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. User Accounts</h2>
        <p className="mb-2">
          If you create an account on our website, you are responsible for maintaining the confidentiality of your account 
          details and for all activities that occur under your account. We reserve the right to terminate accounts, edit 
          or remove content, or cancel orders at our discretion.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. Product and Service Information</h2>
        <p className="mb-2">
          We strive to ensure that the information on our website is accurate, but we do not guarantee that all content 
          is free from errors or omissions. Corbinsoft reserves the right to modify the contents of the website or our 
          services at any time without notice.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Limitation of Liability</h2>
        <p className="mb-2">
          Corbinsoft shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting 
          from your use of, or inability to use, this website or our services, even if we have been advised of the possibility 
          of such damages.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">6. Links to Third-Party Websites</h2>
        <p className="mb-2">
          Our website may contain links to external websites that are not operated by Corbinsoft. We have no control over the 
          content and practices of these websites and cannot accept responsibility for their privacy policies or their content.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">7. Privacy Policy</h2>
        <p className="mb-2">
          Your privacy is important to us. Please review our 
          <a href="/privacy" className="text-blue-500 underline"> Privacy Policy</a> for information on how we collect, use, and 
          protect your personal data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">8. Changes to Terms and Conditions</h2>
        <p className="mb-2">
          We reserve the right to update these Terms and Conditions at any time. Your continued use of the website after 
          any changes will constitute acceptance of the updated terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">9. Governing Law</h2>
        <p className="mb-2">
          These Terms and Conditions are governed by the laws of US, and any disputes arising out of or in 
          connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Austin, Texas.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">10. Contact Us</h2>
        <p className="mb-2">
          If you have any questions about these Terms and Conditions, please contact us at:
        </p>
        <ul>
          <li>Email: <a href="mailto:info@corbinsoft.com" className="text-blue-500 underline">info@corbinsoft.com</a></li>
          <li>Phone: <a href="tel:+15122870221" className="text-blue-500 underline">+15122870221</a></li>
        </ul>
      </section>
    </div>
  );
};

export default TermsAndConditions;
