import React, { useEffect, useState } from "react";
// import { webinars } from "../contexts/webinars"; // Import the webinars array
// import AdminWebinar from "./AdminWebinar";
import axios from "axios";
import { FaSpinner } from "react-icons/fa"; // Import the spinner icon
import videoBackground from "../assets/videos/bg.mp4";
// Initial sample webinars data
// const initialWebinars = [
//   {
//     id: 1,
//     title: "How to Bypass Excel for IMDRF Compliance",
//     videoUrl: "https://www.youtube.com/embed/3OH-E3CNz_Q",
//     description: "An in-depth webinar on Polarion ALM functiones.",
//     date: "September 12, 2024",
//     thumbnail: "https://via.placeholder.com/400",
//   },
//   {
//     id: 2,
//     title: "Accelerating Software Validation",
//     videoUrl: "https://www.youtube.com/embed/video2",
//     description: "Learn how to validate software efficiently using ALM.",
//     date: "October 5, 2024",
//     thumbnail: "https://via.placeholder.com/400",
//   },
// ];
const Webinars = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [webinars, setWebinars] = useState([]);
  const SHEET_API = process.env.REACT_APP_SHEETDB_API
  useEffect(() => {
    // Fetch the webinars data from the SheetDB API
    const fetchWebinars = async () => {
      try {
        const response = await axios.get(SHEET_API);
        setWebinars(response.data);
        // console.log(response.data)
      } catch (error) {
        console.error("Error fetching webinars:", error);
      }finally {
        setLoading(false); // Set loading to false when done
        // console.log(response.data)
      }
    };

    fetchWebinars();
  });

  if (loading) {
    return <div className="h-[90vh] bg-gradient-to-b from-blue-900 to-black flex items-center justify-center "><h1 className="text-4xl md:text-5xl text-yellow-200">Loading...</h1><FaSpinner className="animate-spin text-4xl md:text-5xl text-yellow-200" /> </div>;
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  return (
    <section className="py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-blue-900 to-black text-white relative">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0  object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="max-w-8xl mx-auto relative z-10">
        <h2 className="text-3xl font-extrabold text-center  sm:text-4xl mb-8">
          Webinars
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {webinars.map((webinar) => (
            
            <div key={webinar.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
              
              <img
                className="w-full h-48 object-cover text-black"
                src={webinar.thumbnail.trim()}
                alt={webinar.title}
                onError={(e) => {
                  e.target.src = "https://via.placeholder.com/400"; // Fallback image URL
                }}
              />
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900">
                  {webinar.title}
                </h3>
                <p className="text-sm text-gray-600 mb-4">{webinar.date}</p>
                <p className="text-gray-700 mb-4">
                  {truncateText(webinar.description, 100)} {/* Truncated description */}
                  {webinar.description.length > 100 && (
                    <a href={`/webinars/${webinar.id}`} className="text-blue-600 hover:underline ml-2">
                      Read more
                    </a>
                  )}
                </p>
                <a
                  href={`/webinars/${webinar.id}`} // Link to individual webinar page
                  className="inline-block px-5 py-3 bg-yellow-200 text-black font-medium rounded-md shadow hover:bg-blue-900 hover:text-white transition-all duration-200"
                >
                  Watch Now
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Render the AdminWebinar component, passing the addWebinar function */}
      {/* <AdminWebinar addWebinar={addWebinar} /> */}
    </section>
  );
};

export default Webinars;
