import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import aerospaceImage from "../assets/images/services/jet-engine-4688927_1280.jpg";
import automotiveImage from "../assets/images/services/car-1281640_1280.jpg";
import medicalDevicesImage from "../assets/images/services/analysis-2030265_1280.jpg";
import financialAndInsuranceImage from "../assets/images/services/PolarionALMintheFinancialSector.webp";
import implementationImage from "../assets/images/services/pexels-goumbik-669610.jpg";
import administrationImage from "../assets/images/services/pexels-divinetechygirl-1181360.jpg";
import softwareValidationImage from "../assets/images/services/validation_verification1-1.png";
import supportImage from "../assets/images/services/pexels-artempodrez-5716037.jpg";

function Services() {
  const location = useLocation();

  useEffect(() => {
    // console.log(location.hash.substring(1))
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1)); // Get element by ID (strip the '#' character)
      // console.log(element)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll to the section smoothly
      }
    }
  }, [location]);
  // State to track index of hovered card
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  // Handler to update hoveredCardINdex
  const handleMouseOver = (index) => {
    setHoveredCardIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredCardIndex(null);
  };

  var servicesData = {
    Aerospace: {
      title: "Aerospace",
      image: aerospaceImage,
      info: "Polarion ensures end-to-end traceability and compliance management for aerospace systems and software engineering. Our solution helps organizations meet regulatory standards with forensic-level proof of compliance, enabling efficient project oversight and risk management.",
      isPage: true,
      link: "/aerospace"
    },
    Automotive: {
      title: "Automotive",
      image: automotiveImage,
      info: "Accelerate your compliance with ISO 26262, Automotive SPICE, and CMMI standards through collaborative platforms that connect complex internal and supplier ecosystems. Polarion empowers teams to improve functional safety and streamline the development lifecycle.",
      isPage: true,
      link: "/automotive"
    },
    "Medical Devices": {
      title: "Medical Devices",
      image: medicalDevicesImage,
      info: "Ensure product safety and regulatory compliance for medical device development. Polarion provides comprehensive traceability and risk management solutions to support adherence to FDA, ISO 13485, and other global standards.",
      isPage: true,
      link: "/medicaldevices"
    },
    "Financial & Insurance": {
      title: "Financial & Insurance",
      image: financialAndInsuranceImage,
      info: "Mitigate risk and maintain compliance in the highly regulated financial and insurance sectors. Polarion enables effective management of audits, governance, and adherence to industry regulations, ensuring operational excellence and regulatory assurance.",
      isPage: true,
      link: "/financial&insurance"
    },
    "Polarion ALM Implementation": {
      title: "Polarion ALM Implementation",
      image: implementationImage,
      info: "Our team ensures a seamless Polarion ALM implementation tailored to your organization’s needs. We provide a structured approach to migrate from legacy systems, configure workflows, and optimize processes for improved efficiency and compliance.",
      isPage: false
    },
    "User And Administration Training": {
      title: "User And Administration Training",
      image: administrationImage,
      info: "Comprehensive training programs designed to empower both users and administrators. Our hands-on training ensures that your team can leverage Polarion to its full potential, enhancing productivity and collaboration across your organization.",
      isPage: false

    },
    "Software Validation": {
      title: "Software Validation",
      image: softwareValidationImage,
      info: "Ensure your software meets the highest quality standards with our validation services. We provide rigorous testing and validation processes, ensuring your Polarion implementation aligns with compliance requirements and business objectives.",
      isPage: false

    },
    Support: {
      title: "Support",
      image: supportImage,
      info: "Expert support services to assist with your Polarion ALM environment. From troubleshooting and technical assistance to performance optimization, we provide ongoing support to ensure your operations run smoothly.",
      isPage: false

    },
  };

  servicesData = Object.values(servicesData);

  const getLink  = (link)=>{
    console.log(link)
      if(link){
        return link
      }
      return "/contact"
  }
  return (
    <section id="services" className="mb-10 ">
      <div className="w-full text-center mt-5 ">
        <h2 className="w-full text-center text-5xl font-bold ">Our Services</h2>
        <p className="mt-5 p-5">
          Innovative Solutions for Every Industry, from Aerospace to Software
          Validation — Tailored to Empower Your Success.
        </p>
      </div>
      <div
        id="cards-container"
        className="grid grid-cols-1 gap-6  xl:gap-10 sm:grid-cols-2 lg:grid-cols-4 w-full  p-5 "
      >
        {servicesData.map((service, index) => (
          <a key={index} href={getLink(service.link)}>
            <div
              className="overflow-hidden bg-white  text-black rounded shadow-md cursor-pointer transition-all duration-200 hover:bg-gradient-to-t hover:from-indigo-300 border flex flex-col items-center p-5 text-center h-[100%]"
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div id="content " className="relative h-full ">
                <img
                  className={`${
                    hoveredCardIndex === index
                      ? "scale-110 opacity-15"
                      : "scale-100"
                  } transition-all duration-200 h-full`}
                  src={service.image}
                  alt=""
                />
                {/* Applying conditional margin class based on hover state */}
                <div
                  className={`absolute justify-self-center self-center top-0 left-0 ${
                    hoveredCardIndex === index ? "opacity-100" : "opacity-0"
                  }  pt-5 info text-center transition-all duration-200 `}
                >
                  {service.info}
                  <a
                    href={getLink(service.link)}
                    className="text-blue-500 block"
                  >
                    Know More
                  </a>
                </div>
              </div>

              <h1 className="title    w-full pt-2">
                {service.title}
              </h1>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
}

export default Services;
