import { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

function GoTop() {
  const [showButton, setShowButton] = useState(false);

  // Update the button visibility based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.5) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    showButton && (
      <button
        onClick={scrollToTop}
        className="animate-bounce fixed p-4 border-2 border-gray-400 rounded-full z-50 right-4 bottom-4 bg-yellow-100 hover:bg-gray-200 transition-all duration-200"
      >
        <FaArrowUp className="text-3xl text-black" />
      </button>
    )
  );
}

export default GoTop;
