import React from "react";
import videoBackground from "../assets/videos/bg.mp4";

const WebinarDetail = ({ title, description, videoUrl, publishDate }) => {
  return (
    <section className="bg-gradient-to-b from-blue-900 to-black min-h-screen py-12 px-4 sm:px-6 lg:px-8 relative text-white">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0  object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="max-w-7xl mx-auto relative">
        <h1 className="text-3xl font-extrabold tracking-tight  sm:text-4xl mb-6">
          {title}
        </h1>

        <div className="relative overflow-hidden shadow-lg rounded-lg mb-8">
          <iframe
            className="w-full h-64 sm:h-96 md:h-[450px] lg:h-[500px]"
            src={videoUrl}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div className="bg-blue-900 text-white p-6 rounded-lg shadow-lg mb-12">
          <div className="text-sm text-cyan-200 mb-2">
            Published on {publishDate}
          </div>
          <p className="text-lg  leading-relaxed mb-4">
            {description}
          </p>
          <button className="inline-flex items-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm  bg-yellow-200 text-black hover:bg-cyan-200 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <a href="/contact">Learn More</a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default WebinarDetail;
