import aerospaceImage from "../assets/images/services/jet-engine-4688927_1280.jpg";
import ServicePageTemplate from "../components/ServicePageTemplate";

function Aerospace() {
  const content = {
    title: "Polarion for Aerospace & Transportation",
    info: (
      <>
        <p>
          Ensure precision, compliance, and traceability in aerospace and transportation industries with Polarion ALM. Streamline development processes, meet safety standards, and reduce time-to-market.
        </p>
        <br />
        <p><strong>Key Features:</strong></p>
        <ul className="list-disc list-inside">
          <li>End-to-end traceability for better control.</li>
          <li>Compliance with DO-178C and ISO 26262.</li>
          <li>Accelerated time-to-market with efficient tools.</li>
        </ul>
      </>
    ),
    image: aerospaceImage
  };

  return (
    <ServicePageTemplate
      title={content.title}
      info={content.info}
      image={content.image}
      imageOnLeft={false} // Image on the right
    />
  );
}

export default Aerospace;
