import FinancialAndInsuranceImage from "../assets/images/services/PolarionALMintheFinancialSector.webp";
import ServicePageTemplate from "../components/ServicePageTemplate";

function FinancialAndInsurance() {

  const content = {
    title: "Enabling Traceability and Compliance",
    info: (
      <>
        <p>
          Polarion ALM ensures traceability of requirements and compliance, enabling efficient risk reporting and flexible project management. It supports both classical and agile approaches, ensuring systematic and auditable test documentation.
        </p>
        <br />
        <p>
          Collaborate seamlessly across distributed teams and comply with regulatory requirements by mapping design and code through testing.
        </p>
      </>
    ),
    image: FinancialAndInsuranceImage,
  };
  
  return (
    <ServicePageTemplate 
    title={content.title}
    info={content.info}
    image={content.image}
    imageOnLeft={false}
    />
  );
}

export default FinancialAndInsurance;
