import React from "react";
import buildingImage from "../assets/images/building.jpeg";
import { FaLongArrowAltDown } from "react-icons/fa";
import GoTop from "./GoTop"
import videoBackground from "../assets/videos/bg.mp4";

function About() {
  return (
    <section className="relative">
      <GoTop/>
       {/* Video Background */}
       <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <section
        id="about"
        className=" z-10 bg-gradient-to-b from-blue-900 to-black text-white p-10"
        style={{ scrollBehavior: "revert" }}
      >
        
        <div className="relative text-center mb-12">
          <div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold  sm:text-4xl relative">
              About Us
              <a href="#location" className="absolute text-sm  right-0 bottom-16 md:right-3 lg:bottom-0 text-yellow-200 hover:text-cyan-200 animate-pulse hover:animate-none">Our Business Location <span><FaLongArrowAltDown className="inline"/></span></a>
            </h2>
            <p className="mt-4 text-lg ">
              At <span className="font-semibold text-yellow-200">Corbinsoft</span>
              , we specialize in providing top-tier ALM solutions to help
              businesses optimize their processes.
            </p>
          </div>
          <div className="grid grid-cols-1 text-left md:grid-cols-2 gap-8 items-center">
            <div className="max-w-3xl">
              <h3 className="text-2xl font-bold ">
                Our Expertise
              </h3>
              <p className="mt-4 text-cyan-200">
                We are experts in implementing and optimizing{" "}
                <span className="font-semibold">Siemens Polarion ALM</span>{" "}
                across various industries, from aerospace to medical devices.
                Our goal is to streamline your product development lifecycle,
                ensuring better collaboration, traceability, and compliance.
              </p>

              <h3 className="mt-8 text-2xl font-bold ">
                Tailored Solutions
              </h3>
              <p className="mt-4 text-cyan-200">
                Every business has unique needs, and we’re here to provide
                solutions that fit. Whether it’s Polarion customization, user
                training, or technical support, we work closely with you to
                tailor our services to meet your exact requirements.
              </p>

              <h3 className="mt-8 text-2xl font-bold ">
                Our Vision
              </h3>
              <p className="mt-4 text-cyan-200">
                We aim to be the leading provider of{" "}
                <span className="font-semibold">
                  Application Lifecycle Management
                </span>{" "}
                services, helping businesses unlock their potential by
                optimizing ALM systems and driving business growth.
              </p>

              <div className="mt-8">
                <a
                  href="/contact"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  Contact us to know more
                  <span className="ml-2">&#8594;</span>
                </a>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <img
                src={buildingImage}
                alt="CorbinSoft Expertise"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      <section id="location" className="rounded-lg  bg-blue-950 relative p-10 mb-10 mx-3 text-white ">
        {/* <a
          href="/contact"
          className="bg-white text-black hover:bg-yellow-100 py-3  px-16 rounded-md absolute right-52 bottom-52"
        >
          Contact
        </a> */}
        <h1 className="text-center mt-10 mb-5 text-4xl font-bold">Our Business Location</h1>
        <div className="flex flex-col md:flex-row justify-around text-xl mt-16 ">
          <div>
            <div className="mb-5">
              <p>Corbinsoft LLC</p>
            </div>
            <div className="mb-5">
              <p>13809 Research Blvd </p>
              <p>Suite 500,</p>
            </div>
            <div className="mb-5">
              <p>Austin, TX 78750</p>
              <p>USA</p>
            </div>
            <div className="mb-5">
              <p>Tel.: +1 5122870221</p>
            </div>
            {/* <div className="mb-5">
              <p>Office Timing:</p>
              <p>8.00am to 5.00pm (Monday to Friday)</p>
            </div> */}
            {/* <p>Call us: 1800 209 1800 (Mon-Sat 8am to 8pm)</p> */}
          </div>
          <div className="bg-white h-1 w-full mt-3 md:hidden"></div>
          <div className="mt-5 md:mt-0">
            {/* <div className="mb-5">
              <p>Industrial Services</p>
            </div>
            <div className="mb-5">
              <p>1800 220 0987 (MTNL/BSNL)</p>
              <p>1800 209 0987 (All Lines)</p>
            </div>
            <div className="mb-5">
              <p>Healthineers Customer Service</p>
              <p>1800 419 7477</p>
            </div> */}
            <p>Write to us!</p>
            <a href="/contact" className="text-cyan-200 underline hover:text-yellow-100 inline-block mt-2 animate-pulse hover:animate-none">Contact</a>
            </div>
        </div>
      </section>
      </section>
    </section>
  );
}

export default About;
