import { useState } from "react";
import emailjs from "@emailjs/browser";
import contactImage from "../assets/images/contact.jpg";
import Swal from "sweetalert2";
import videoBackground from "../assets/videos/bg.mp4";

function Contact() {
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

 // State to track loading status
 const [isSubmitting, setIsSubmitting] = useState(false);
  //state to store form values
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    interestedIn: "",
    servicesNeed: "",
    message: "",
    phone: "", // New field for phone
    countryCode: "+1", // Default country code
  });
  //state to store validation errors
  const [errors, setErrors] = useState({});
  //function to handle changes in the fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  //function to handle form validation
  const validate = (data) => {
    const errors = {};

    //name validation
    if (!data.username) {
      errors.username = "Name is required";
    } else if (data.username.length < 2) {
      errors.username = "Name must be at least 2 characters";
    }
    //Email Validation
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    // Phone validation
    // if (!data.phone) {
    //   errors.phone = "Phone number is required";
    // } else
    if (!/^\d{7,15}$/.test(data.phone)) {
      errors.phone = "Phone number must be between 7 and 15 digits";
    }
    // Interested In validation
    if (!data.interestedIn) {
      errors.interestedIn = "Please select an option";
    }
    // Services Need validation
    if (!data.servicesNeed) {
      errors.servicesNeed = "Please select an option";
    }
    // Message validation
    if (!data.message) {
      errors.message = "Message is required";
    } else if (data.message.length < 10) {
      errors.message = "Message must be at least 10 characters";
    }

    return errors;
  };
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set loading state to true when form is submitted
    const formErrors = validate(formData);
    // console.log("Errors: "+Object.keys(formErrors));
    // console.log(Object.keys(formErrors).length)
    if (Object.keys(formErrors).length === 0) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_KEY).then(
        (result) => {
          setFormData({
            username: "",
            email: "",
            interestedIn: "",
            servicesNeed: "",
            message: "",
            phone: "", // Reset phone field
            countryCode: "+1", // Reset country code
          });
          // console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Sent!",
            text: "Your message has been successfully sent. We'll get back to you shortly.",
            confirmButtonText: "Ok",
            confirmButtonColor: "#4CAF50", 
          });
          setIsSubmitting(false); // Reset loading state after successful submission
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "There was an issue sending your message. Please try again later.",
            confirmButtonText: "Ok",
            confirmButtonColor: "#d33", 
          });
          setIsSubmitting(false); // Reset loading state after error
        }
      );
    } else {
      setErrors(formErrors);
    }
  };
  return (
    <section className="bg-gradient-to-b from-blue-900 to-black relative">
       {/* Video Background */}
       <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0  object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <section class=" pb-10 px-10 relative">
        <div class="grid grid-cols-1 lg:grid-cols-2 border-2 rounded-lg overflow-hidden">
          <div class="relative  items-end px-4 pb-10 pt-60 sm:pb-16 md:justify-center lg:pb-24 bg-gray-50 sm:px-6 lg:px-8 hidden lg:flex  overflow-hidden">
            <div class="absolute inset-0 ">
              <img
                class="object-cover object-top w-full h-full"
                src={contactImage}
                alt=""
              />
            </div>
            <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>

            <div class="relative ">
              <div class="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-24 xl:max-w-xl">
                <h3 class="text-4xl font-bold text-white">
                  Join 10+ professionals & <br class="hidden xl:block" />
                  upscale your business by 10 times
                </h3>
                <ul class="grid grid-cols-1 mt-10 sm:grid-cols-2 gap-x-8 gap-y-4">
                  <li class="flex items-center space-x-3">
                    <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                      <svg
                        class="w-3.5 h-3.5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <span class="text-lg font-medium text-white">
                      {" "}
                      Full Support{" "}
                    </span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                      <svg
                        class="w-3.5 h-3.5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <span class="text-lg font-medium text-white">
                      {" "}
                      Immediate Response{" "}
                    </span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                      <svg
                        class="w-3.5 h-3.5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <span class="text-lg font-medium text-white">
                      {" "}
                      Live Demonstrations{" "}
                    </span>
                  </li>
                  <li class="flex items-center space-x-3">
                    <div class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 bg-blue-500 rounded-full">
                      <svg
                        class="w-3.5 h-3.5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <span class="text-lg font-medium text-white">
                      {" "}
                      Tailored Solutions{" "}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-center sm:px-6 sm:py-16 md:py-0 md:px-0 mb-6 text-white">
            <form
              onSubmit={handleSubmit}
              className=" shadow-md rounded-md px-8 pt-6 pb-8  w-full max-w-3xl "
            >
              <h1 className="text-3xl text-center">Contact Us</h1>
              {/* Name Field */}
              <div className="mb-4">
                <label
                  htmlFor="username"
                  className="block  font-bold mb-2"
                >
                  Name
                </label>
                <input
                  id="username"
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black  leading-tight focus:outline-none focus:shadow-lg"
                />
                {errors.username && (
                  <p className="text-yellow-100
                   text-xs mt-1">{errors.username}</p>
                )}
              </div>
              {/* Email Field */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block  font-bold mb-2 "
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="text-black shadow appearance-none border rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-lg"
                />
                {errors.email && (
                  <p className="text-red-600 text-xs mt-1">{errors.email}</p>
                )}
              </div>
              {/* Country code and phone number */}
              <div className="mb-4">
                <label class="text-base font-medium ">
                  Phone Number (Optional)
                </label>
                <div class="flex space-x-4 mt-2.5 text-black ">
                  <select
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    class="px-4 py-4 border border-gray-300 rounded-md"
                  >
                    <option value="+1">+1 (USA)</option>
                    <option value="+44">+44 (UK)</option>
                    <option value="+91">+91 (India)</option>
                    {/* Add more country codes here */}
                  </select>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    class={`block w-full px-4 py-4 placeholder-white text-white bg-transparent border ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    } rounded-md`}
                    placeholder="Enter your phone number"
                  />
                </div>
                {errors.phone && (
                  <span class="text-red-500">{errors.phone}</span>
                )}
              </div>
              {/* Selection*/}
              <div className="mb-4 w-fit inline-block mr-16">
                <label htmlFor="interested" className="block font-bold mb-2">
                  Interested in
                </label>
                <select
                  name="interestedIn"
                  value={formData.interestedIn}
                  onChange={handleChange}
                  id="interested"
                  className="shadow  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-lg"
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="ALM">
                    Application Lifestyle Management (ALM)
                  </option>
                  <option value="Professional Services">
                    Professional Services
                  </option>
                  <option value="Other Assistance">Other Assistance</option>
                </select>
                {errors.interestedIn && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.interestedIn}
                  </p>
                )}
              </div>

              {/* Services Need Selection */}
              <div className="mb-4 w-fit inline-block">
                <label className="block  text-sm font-bold mb-2">
                  Services Need
                </label>
                <select
                  name="servicesNeed"
                  value={formData.servicesNeed}
                  onChange={handleChange}
                  className="shadow  border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  <option value="Medical Device">Medical Device</option>
                  <option value="Aerospace">Aerospace</option>
                  <option value="Automotive">Automotive</option>
                  <option value="Financial and Insurance">
                    Financial and Insurance
                  </option>
                  <option value="Others">Others</option>
                </select>
                {errors.servicesNeed && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.servicesNeed}
                  </p>
                )}
              </div>

              {/* Rich Text - Message */}
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block  text-sm font-bold mb-2"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  id="message"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 sm:text-sm md:text-md"
                  placeholder="Please provide detailed information or describe your request. "
                />
                {errors.message && (
                  <p className="text-red-600 text-xs mt-1">{errors.message}</p>
                )}
              </div>

              {/* Submit Button */}
              <div className="flex  w-full items-center  justify-center">
                <button
                  type="submit"
                  className="bg-yellow-200 hover:bg-cyan-200 text-black font-bold py-2 px-4 rounded focus:outline-none transition-all duration-200 focus:shadow-outline w-2/4 "
                >
                 {isSubmitting ? "Submitting..." : "Submit"} {/* Button text changes based on loading state */}
       
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Contact;
