// import polarionprofile from "../assets/images/polarionprofile.png";
// import collaborationSVG from "../assets/icons/collaboration.svg";
// import Aerospace from "../pages/Aerospace";
// import Automotive from "../pages/Automotive";
// import FinancialAndInsurance from "../pages/FinancialAndInsurance";
// import MedicalDevices from "../pages/MedicalDevices";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import Ad from "./Ad";
import GoTop from "./GoTop";
import aerospaceImage from "../assets/images/services/jet-engine-4688927_1280.jpg";
import automotiveImage from "../assets/images/services/car-1281640_1280.jpg"
import medicalDevicesImage from "../assets/images/services/analysis-2030265_1280.jpg"
import financialImage from "../assets/images/services/PolarionALMintheFinancialSector.webp"
import videoBackground from "../assets/videos/bg.mp4";

export default function Solutions() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <section className="bg-gradient-to-b from-blue-900 to-black min-h-screen relative">
      <div className="hidden md:block">
        <GoTop />
      </div>
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <section className="w-full flex flex-col lg:flex-row relative justify-evenly p-6 lg:p-10">
        {/* Left div for images */}
        <div className="left hidden  lg:block mt-10 lg:mt-20">
          <div className="img-container mb-32 rotate-[-12deg] relative w-[300px] h-[400px]">
            <img
              src={financialImage}
              alt=""
              className="absolute w-full h-full rounded-lg rotate-[10deg] top-10 left-28 z-10 shadow-lg shadow-yellow-200"
            />
            <img
              src={medicalDevicesImage}
              alt=""
              className="absolute w-full h-full rounded-lg rotate-[2deg] top-6 left-12 z-20"
            />
            <img
              src={automotiveImage}
              alt=""
              className="absolute w-full h-full rounded-lg rotate-[-12deg] top-6 right-2 z-30"
            />
            <img
              src={aerospaceImage}
              alt=""
              className="absolute w-full h-full rounded-lg rotate-[-20deg] top-9 right-20 z-40 shadow-sm shadow-yellow-200"
            />
          </div>
        </div>
        {/* Right div for content */}
        <div className="right lg:ml-20 mt-10 lg:mt-0 self-center">
          <ul className="space-y-6">
            <li>
              <a href="/aerospace" className="group">
                <h2 className="text-white text-4xl font-bold group-hover:underline group-hover:text-yellow-200 hover:text-yellow-200">
                  Aerospace
                </h2>
                <p className="text-cyan-400 text-xl group-hover:text-cyan-300">
                  Explore solutions for the aerospace industry.
                </p>
              </a>
            </li>
            <li>
              <a href="/automotive" className="group">
                <h2 className="text-white text-4xl font-bold group-hover:underline group-hover:text-yellow-200 hover:text-yellow-200">
                  Automotive
                </h2>
                <p className="text-cyan-400 text-xl group-hover:text-cyan-300">
                  Learn how we help the automotive sector.
                </p>
              </a>
            </li>
            <li>
              <a href="/medicaldevices" className="group">
                <h2 className="text-white text-4xl font-bold group-hover:underline group-hover:text-yellow-200 hover:text-yellow-200">
                  Medical Devices
                </h2>
                <p className="text-cyan-400 text-xl group-hover:text-cyan-300">
                  Innovate with medical device solutions.
                </p>
              </a>
            </li>
            <li>
              <a href="/Financial&Insurance" className="group">
                <h2 className="text-white text-4xl font-bold group-hover:underline group-hover:text-yellow-200 hover:text-yellow-200">
                  Financial & Insurance
                </h2>
                <p className="text-cyan-400 text-xl group-hover:text-cyan-300">
                  Tailored services for financial and insurance industries.
                </p>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  );
}
