import automotiveImage from "../assets/images/services/car-1281640_1280.jpg";
import ServicePageTemplate from "../components/ServicePageTemplate";

function Automotive() {
  const content = {
    title: "Polarion for Automotive Industry",
    info: (
      <>
        <p>
          Accelerate innovation in automotive with Polarion ALM. Manage complex product development, ensure compliance with ASPICE and ISO 26262, and enhance collaboration across teams.
        </p>
        <br />
        <p><strong>Key Benefits:</strong></p>
        <ul className="list-disc list-inside">
          <li>Compliance with automotive standards.</li>
          <li>Seamless management of embedded systems.</li>
          <li>Improved real-time collaboration.</li>
        </ul>
      </>
    ),
    image: automotiveImage
  };

  return (
    <ServicePageTemplate
      title={content.title}
      info={content.info}
      image={content.image}
      imageOnLeft={true} // Image on the left
    />
  );
}

export default Automotive;
