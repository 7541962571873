import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import WebinarDetail from "../components/WebinarDetail";
import { FaSpinner } from "react-icons/fa"; // Import the spinner icon
const WebinarPage = () => {
  const { id } = useParams(); // Get webinar ID from the URL
  const [webinar, setWebinar] = useState(null); // State to hold the webinar data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // Fetch the webinar details by ID from the SheetDB API
    const fetchWebinar = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SHEETDB_API}/search?id=${id}`);

        if (response.data.length > 0) {
          setWebinar(response.data[0]); // Set the first result as the webinar
        } else {
          setError("Webinar not found!"); // Set error if no webinar is found
        }
      } catch (error) {
        setError("Error fetching webinar data.");
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchWebinar();
  }, [id]); // Re-fetch whenever the id changes

  if (loading) {
    return <div className="h-[90vh] flex items-center justify-center "><h1 className="text-4xl md:text-5xl">Loading...</h1><FaSpinner className="animate-spin text-4xl md:text-5xl" /> </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <WebinarDetail
      title={webinar.title}
      description={webinar.description}
      videoUrl={webinar.videoUrl}
      publishDate={webinar.date}
    />
  );
};

export default WebinarPage;
