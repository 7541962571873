import { Link } from "react-router-dom";
import { RiCustomerService2Fill } from "react-icons/ri";
import videoBackground from "../assets/videos/bg.mp4";

function ServicePageTemplate({ title, info, image, imageOnLeft }) {
  return (
    <section className="p-10  bg-gradient-to-b from-blue-900 to-black overflow-hidden relative">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="opacity-10 absolute top-0 left-0  object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <section className="relative z-10  mx-auto max-w-[90vw] rounded-xl overflow-hidden">
        <div className="px-4  sm:px-6 lg:px-8">
          <div className="py-10 sm:py-16 lg:py-20 2xl:pl-16">
            <div
              className={`grid items-center grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20 `}
            >
              {/* Conditionally place image on the left or right */}
              <div
                className={`${
                  imageOnLeft ? "lg:order-1" : "lg:order-2"
                } w-full h-full`}
              >
                <img
                  className="w-full h-full object-cover shadow-xl rounded-xl"
                  src={image}
                  alt={title}
                />
              </div>

              <div className={`${imageOnLeft ? "lg:order-2" : "lg:order-1"}`}>
                <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl lg:leading-snug">
                  {title}
                </h2>
                <p className="text-white mt-5">{info}</p>

                <div className="flex flex-col items-center mt-8 sm:space-x-4 sm:flex-row sm:items-center lg:mt-12">
                  <Link
                    to="/contact"
                    className="w-2/4 inline-flex items-center justify-center px-4 py-4 text-base font-semibold text-black transition-all duration-200 outline border border-transparent rounded-md bg-yellow-200  hover:bg-yellow-50 hover:shadow-md hover:shadow-yellow-200"
                    role="button"
                  >
                    <RiCustomerService2Fill className="mr-2" />
                    Schedule a demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default ServicePageTemplate;
