import { useEffect, useRef } from "react";

export default function VideoModal({ isModalOpen, setIsModalOpen }) {
  const modalRef = useRef(null);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    
    // Add event listener to detect clicks outside modal
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    
    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen, setIsModalOpen]);

  if (!isModalOpen) return null;  // Return nothing if modal is not open

  return (
    <section>
      <div
        ref={modalRef}
        className="flex overflow-hidden rounded-xl items-center justify-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] sm:h-[50%] md:h-[70%]  z-30 shadow-3xl border-[15px] border-white"
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/B5IetpL_VYc?si=d-wgNqv3RD-N4URo"
          title="YouTube video player"
         
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}
