import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Explore from "./components/Solutions";
import Header from "./components/Header";
import React from "react";
import Wrapper from "./components/Wrapper";
import logo from "./assets/images/logo.png";
import Footer from "./components/Footer";
import { CardsProvider } from "./contexts/DataContext";
import Contact from "./components/Contact";
import Services from "./pages/Services";
import Webinars from "./pages/Webinars";
import WebinarPage from "./pages/WebinarPage";
import Blog from "./pages/Blog";
import About from "./components/About";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aerospace from "./pages/Aerospace";
import Automotive from "./pages/Automotive";
import MedicalDevices from "./pages/MedicalDevices";
import FinancialAndInsurance from "./pages/FinancialAndInsurance";
function App() {
  const details = {
    title: "Corbinsoft",
    logo: logo,
    navlist: ["home", "solutions", "services", "webinars", "about"],
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Header header={details} />

        <Routes>
          <Route
            path="/"
            element={
              <CardsProvider>
                <Home />
              </CardsProvider>
            }
          />
          <Route
            path="/home"
            element={
              <CardsProvider>
                <Home />
              </CardsProvider>
            }
          />
          <Route path="/solutions" element={<Explore />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home#services" element={<Services />} />
          <Route path="/webinars" element={<Webinars />} />
          <Route path="/webinars/:id" element={<WebinarPage />} />
          <Route path="/blogs" element={<Blog/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/terms" element={<TermsAndConditions/>}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
          <Route path="/aerospace" element={<Aerospace/>}/>
          <Route path="/automotive" element={<Automotive/>}/>
          <Route path="/medicaldevices" element={<MedicalDevices/>}/>
          <Route path="/financial&insurance" element={<FinancialAndInsurance/>}/>
        </Routes>

        <Footer footer={details} />
      </Wrapper>
    </React.Fragment>
  );
}

export default App;
