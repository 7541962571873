import { useState } from "react";
import IntegrateWithApps from "../components/IntegrateWithApps";
import VideoModal from "../components/VideoModal";
import polarionCycleImg from "../assets/images/pages/a-2d-circular-workflow-diagram-with-a-professional-m_dlj4r9S5SFNOAL3cN5wQ-PKtbFDegQduGWbpk2STX1A.jpeg";
import { Link } from "react-router-dom";
import GoTop from "../components/GoTop";
import Services from "./Services";
import Ad from "../components/Ad";
import videoBackground from "../assets/videos/bg.mp4";
// import About from "../components/About";
// import AboutBanner from "../components/AboutBanner";
function Home() {
  var [isModalOpen, setIsModalOpen] = useState(false);
  function openVideoModal() {
    // console.log(isModalOpen)
    setIsModalOpen(true);
  }
  return (
    <section className="bg-gradient-to-tr relative from-blue-800 to-black text-white ">
      {/* <section className="bg-gradient-to-b from-green-50 to-gray-400 "> */}

      {/* <div className="bg-gradient-to-b from-green-50 to-green-100"> */}
      <GoTop />

      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="opacity-30 absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative z-30">
        {/* <div className="bg-gradient-to-b from-green-50 to-gray-400 relative"> */}
        <VideoModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

        <section className="py-10 sm:py-16 lg:py-22">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
              <div>
                <h1 className=" text-4xl font-bold  sm:text-6xl lg:text-6xl">
                  Streamline Your Polarion Experience, With{" "}
                  <div className="relative inline-flex">
                    <span className="absolute inset-x-0 bottom-0 border-b-[30px] border-black"></span>
                    <h1 className="relative text-4xl font-bold  sm:text-6xl lg:text-7xl ">
                      Corbinsoft.
                    </h1>
                  </div>
                </h1>

                <p className="mt-8 text-base  sm:text-xl">
                  Expert Guidance and Solutions for Optimized Siemens Polarion
                  Workflows
                </p>

                <div className="mt-10 flex sm:flex sm:items-center sm:space-x-8  ">
                  <Link
                    to="/solutions"
                    title=""
                    className="inline-flex items-center justify-center px-10 py-4 text-base font-semibold text-black transition-all duration-200 bg-yellow-100 hover:bg-slate-50 hover:shadow-md hover:shadow-orange-300  focus:bg-orange-600 rounded-full"
                    role="button"
                  >
                    {" "}
                    Start exploring{" "}
                  </Link>

                  <button
                    title=""
                    className="rounded-full justify-center outline-dashed outline-1 px-5 py-2 inline-flex items-center ml-5 text-base font-semibold transition-all duration-200 sm:mt-0 hover:opacity-80 hover:bg-yellow-100 hover:text-black "
                    onClick={openVideoModal}
                  >
                    <svg
                      className="w-10 h-10 mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        fill="/homeF97316"
                        stroke="/homeF97316"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Watch video
                  </button>
                </div>
              </div>

              <div className="rounded-full overflow-hidden">
                <img
                  className="w-full h-[70vh]"
                  src={polarionCycleImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
      </div>
      <div className=" relative z-30">
        {/* <AboutBanner/> */}
        <Services />
        <Ad />
        <IntegrateWithApps />
        {/* <About/> */}
      </div>
    </section>
  );
}

export default Home;
