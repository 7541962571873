import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>

      <p className="mb-4">
        At Corbinsoft, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and 
        safeguard your personal information when you visit our website, <a href="/home" className="text-blue-500 underline">www.corbinsoft.com</a>.
        By using our website, you consent to the practices outlined in this policy.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Information We Collect</h2>
        <p className="mb-2">
          We collect personal information that you voluntarily provide to us when registering on the website, expressing interest in 
          obtaining information about our services, or otherwise contacting us. This may include your name, email address, phone number, 
          and any other information you choose to provide.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">2. How We Use Your Information</h2>
        <p className="mb-2">
          We may use the information we collect to:
        </p>
        <ul className="list-disc pl-5 mb-2">
          <li>Respond to your inquiries and provide customer support</li>
          <li>Improve our website and services</li>
          <li>Send you updates, marketing communications, or promotional offers (if you have opted in)</li>
          <li>Analyze how users interact with our website</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. Sharing Your Information</h2>
        <p className="mb-2">
          We do not share your personal information with third parties except in the following circumstances:
        </p>
        <ul className="list-disc pl-5 mb-2">
          <li>With your consent</li>
          <li>To comply with legal obligations or requests</li>
          <li>In connection with any merger, sale, or transfer of our company’s assets</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. Data Security</h2>
        <p className="mb-2">
          We take appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or 
          destruction. However, please be aware that no data transmission over the internet is entirely secure, and we cannot guarantee 
          the absolute security of your information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Cookies</h2>
        <p className="mb-2">
          Our website uses cookies to enhance your experience and improve functionality. You can choose to accept or decline cookies through 
          your browser settings. However, declining cookies may affect your ability to use certain features of the website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">6. Your Rights</h2>
        <p className="mb-2">
          You have the right to access, update, or delete your personal information at any time. If you would like to review or make changes 
          to your information, please contact us using the contact information below.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">7. Changes to This Policy</h2>
        <p className="mb-2">
          We reserve the right to update or change this Privacy Policy at any time. We will notify you of any significant changes by 
          posting a notice on our website or contacting you directly. Your continued use of the website after such changes constitutes your 
          acceptance of the updated policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">8. Contact Us</h2>
        <p className="mb-2">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <ul>
          <li>Email: <a href="mailto:info@corbinsoft.com" className="text-blue-500 underline">info@corbinsoft.com</a></li>
          <li>Phone: <a href="tel:+15122870221" className="text-blue-500 underline">+15122870221</a></li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
