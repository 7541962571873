// import { Link, NavLink } from "react-router-dom";

function Footer({ footer }) {
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const getLink = (item) => {
    if (item === "services") {
      return "/home#services";
    }
    return `/${item}`;
  };
  return (
    <section className="py-10 bg-gray-900 sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 gap-x-5 gap-y-12 md:grid-cols-4 md:gap-x-12">
          <div>
            <p className="text-base text-gray-500">Company</p>

            <ul className="mt-8 space-y-4">
              {footer.navlist.map((item, index) => (
                <li key={index}>
                  <a
                    href={getLink(item)}
                    className={`text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80`}
                  >
                    {capitalize(item)}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <p className="text-base text-gray-500">Resources</p>

            <ul className="mt-8 space-y-4">
              {/* <li>
                <a
                  href="/"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                >
                  {" "}
                  Guides{" "}
                </a>
              </li> */}
              <li>
                <a
                  href="https://youtu.be/9eZjS2sWgGY?si=AH54I0Owz79frR77"
                  title=""
                  className="text-base text-white transition-all duration-200  hover:text-yellow-200 focus:text-opacity-80"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Getting Started with Agile{" "}
                </a>
              </li>
              <li>
                <a
                  href="/blogs"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80"
                >
                  {" "}
                  Our Blogs{" "}
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@corbinsoftpolarionalmsoftw2482?si=bemyT_uBnsOm1xzV"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  YouTube {" "}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <p className="text-base text-gray-500">Extra Links</p>

            <ul className="mt-8 space-y-4">
              <li>
                <a
                  href="/contact"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80"
                >
                  {" "}
                  Customer Support{" "}
                </a>
              </li>
              {/* <li>
                <a
                  href="/"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                >
                  {" "}
                  Delivery Details{" "}
                </a>
              </li> */}
              <li>
                <a
                  href="/terms"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80"
                >
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </li>
              <li>
                <a
                  href="/privacy"
                  title=""
                  className="text-base text-white transition-all duration-200 hover:text-yellow-200 focus:text-opacity-80"
                >
                  {" "}
                  Privacy Policy{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr className="mt-16 mb-10 border-gray-800" />

        <div className="flex flex-wrap items-center justify-between">
          <div className="md:order-1 flex items-center  border-white">
            <img
              className="h-10 auto md:order-1 rounded-full"
              src={footer.logo}
              alt=""
            />
            <h1 className="ml-5 md:order-2 text-white md:text-4xl">
              {footer.title}
            </h1>
          </div>

          <ul className="flex items-center space-x-3 md:order-3">
            <li>
              <a
                href="/home"
                title=""
                className="flex items-center justify-center text-white transition-all duration-200 bg-transparent border border-gray-700 rounded-full w-7 h-7 focus:bg-blue-600 hover:bg-yellow-200 hover:text-black hover:border-blue-600 focus:border-blue-600"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                </svg>
              </a>
            </li>

            <li>
              <a
                href="/home"
                title=""
                className="flex items-center justify-center text-white transition-all duration-200 bg-transparent border border-gray-700 rounded-full w-7 h-7 focus:bg-blue-600 hover:bg-yellow-200 hover:text-black hover:border-blue-600 focus:border-blue-600"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                </svg>
              </a>
            </li>

            {/* linkedin icon */}
            <li>
              <a
                href="https://www.linkedin.com/company/51654655/admin/dashboard/"
                target="_blank"
                rel="noreferrer"
                title=""
                className="flex items-center justify-center text-white transition-all duration-200 bg-transparent border border-gray-700 rounded-full w-7 h-7 focus:bg-blue-600 hover:bg-yellow-200 hover:text-black hover:border-blue-600 focus:border-blue-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M19.998 3.001C21.105 3.001 22 3.897 22 5.001V19c0 1.104-.895 2-2.002 2H4.002A2.002 2.002 0 012 19V5.001C2 3.897 2.895 3.001 4.002 3.001h15.996zM8.338 18.669V10.67H5.664v7.999h2.674zM7.001 9.39a1.555 1.555 0 110-3.11 1.555 1.555 0 010 3.11zM18.336 18.669v-4.365c0-2.12-1.127-3.107-2.63-3.107-1.212 0-1.764.667-2.066 1.136v-.974h-2.675v7.999h2.675v-4.295c0-.596.045-1.192.217-1.622.477-.942 1.272-.942 1.647-.942 1.106 0 1.536.714 1.536 2.01v4.85h2.674z" />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="/home"
                title=""
                className="flex items-center justify-center text-white transition-all duration-200 bg-transparent border border-gray-700 rounded-full w-7 h-7 focus:bg-blue-600 hover:bg-yellow-200 hover:text-black hover:border-blue-600 focus:border-blue-600"
              >
                <svg
                  className="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.026 2c-5.509 0-9.974 4.465-9.974 9.974 0 4.406 2.857 8.145 6.821 9.465.499.09.679-.217.679-.481 0-.237-.008-.865-.011-1.696-2.775.602-3.361-1.338-3.361-1.338-.452-1.152-1.107-1.459-1.107-1.459-.905-.619.069-.605.069-.605 1.002.07 1.527 1.028 1.527 1.028.89 1.524 2.336 1.084 2.902.829.091-.645.351-1.085.635-1.334-2.214-.251-4.542-1.107-4.542-4.93 0-1.087.389-1.979 1.024-2.675-.101-.253-.446-1.268.099-2.64 0 0 .837-.269 2.742 1.021a9.582 9.582 0 0 1 2.496-.336 9.554 9.554 0 0 1 2.496.336c1.906-1.291 2.742-1.021 2.742-1.021.545 1.372.203 2.387.099 2.64.64.696 1.024 1.587 1.024 2.675 0 3.833-2.33 4.675-4.552 4.922.355.308.675.916.675 1.846 0 1.334-.012 2.41-.012 2.737 0 .267.178.577.687.479C19.146 20.115 22 16.379 22 11.974 22 6.465 17.535 2 12.026 2z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>

          <p className="w-full mt-8 text-sm text-center text-gray-100 md:mt-0 md:w-auto md:order-2">
            © Copyright {new Date().getFullYear()}, All Rights Reserved by
            Corbinsoft
          </p>
        </div>
      </div>
    </section>
  );
}

export default Footer;
