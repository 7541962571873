import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

export default function IntegrateWithApps() {
  var cardsData = useContext(DataContext);
  cardsData = Object.values(cardsData)
  // console.log(cardsData)
  return (
    <section className="py-10 text-white sm:py-16 lg:py-14">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight  sm:text-4xl lg:text-5xl">
            Integrate with softwares
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-cyan-200">
            Get hands-on solutions on achieving integrations with other ALM/PLM Tools
          </p>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-12 lg:mt-16 xl:gap-10 sm:grid-cols-2 lg:grid-cols-3">

          {cardsData.map((card, index)=>(
            <div key={index} className="overflow-hidden bg-gradient-to-t from-cyan-300 to-yellow-50 rounded-3xl shadow-md cursor-pointer hover:scale-105 transition-all duration-200 hover:bg-gradient-to-t hover:from-indigo-300 hover:to-white">
            <div className="p-8">
              <div className="flex items-center">
                <img
                  className="flex-shrink-0 w-12 h-auto"
                  src={card.icon}
                  alt=""
                />
                <div className="ml-5 mr-auto">
                  <p className="text-xl font-semibold text-black">{card.title}</p>
                  <p className="mt-px text-sm text-gray-600">{card.tag}</p>
                </div>
                <svg
                  className="hidden w-5 h-5 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </div>
              <p className="text-base leading-relaxed text-gray-600 mt-7">
              {card.content}
              </p>
            </div>
          </div>
          ))}
          

         

        </div>

        <div className="mt-12 text-center">
          <a
            href="/contact"
            title=""
            className="inline-flex p-3 font-medium text-yellow-200 transition-all duration-200 hover:text-yellow-100 focus:text-blue-700 hover:underline"
          >
            {" "}
            Check all possible integrations{" "}
          </a>
        </div>
      </div>
    </section>
  );
}
