import { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
function Header({ header }) {
  const [activeLink, setActiveLink] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const solutions = [
    "Aerospace",
    "Automotive",
    "Medical Devices",
    "Financial & Insurance",
  ];
  const [isDropDownOpen, setIsDropDownOpen] = useState(true);
  const handleDropDown = () => {
    // console.log(isDropDownOpen);
    setIsDropDownOpen(!isDropDownOpen);
  };
  const buttonRef = useRef(null); // Ref for the button
  const navbarRef = useRef(null); // Ref for the navbar
  const dropDownRef = useRef(null);
  useEffect(() => {
    const handleClickOrScrollOutside = (event) => {
      // Check if click is outside both button and navbar

      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false); // Close menu if clicked outside
      }
    };
    const handleClickOutsideDropDown = (event) => {
      // Check if the click is outside the dropdown (dropDownRef)
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsDropDownOpen(false); // Close dropdown if clicked outside
      }
    };

    // Attach click event listener to the document to detect clicks outside
    document.addEventListener("mousedown", handleClickOutsideDropDown);

    // Attach the click event listener to the whole window
    window.addEventListener("click", handleClickOrScrollOutside);
    window.addEventListener("scroll", handleClickOrScrollOutside);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("click", handleClickOrScrollOutside);
      window.removeEventListener("scroll", handleClickOrScrollOutside);
      document.removeEventListener("mousedown", handleClickOutsideDropDown);
    };
  });

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleNavClick = (item) => {
    setActiveLink(item);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const getLink = (item) => {
    if (item === "services") {
      return "/home#services";
    }
    return `/${item}`;
  };
  return (
    <header className="bg-gradient-to-t from-blue-900 to-black text-white relative z-50" >
      <div className=" px-4 mx-auto sm:px-6 lg:px-8  z-50 ">
        <div className="flex items-center justify-between h-16 lg:h-20">
          <div id="title-container" className=" flex-shrink-0">
            <a href="/home" title="" className="flex text-2xl font-bold">
              <img
                className="w-auto h-8 rounded-full"
                src={header.logo}
                alt=""
              />
              <h1 className="ml-2 ">{header.title.toUpperCase()}</h1>
            </a>
          </div>
          <div id="btn-container" className="order-3">
            <button
              ref={buttonRef}
              type="button"
              onClick={toggleMenu}
              className="rounded inline-flex p-1  transition-all duration-200 border border-black bg-yellow-100 text-black lg:hidden  h"
            >
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} w-6 h-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className={`${isMenuOpen ? "block" : "hidden"} w-6 h-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="desktop-navbar-container"
            className="hidden ml-auto lg:flex lg:items-center lg:justify-center lg:space-x-10"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
              {header.navlist.map((item) => (
                <li
                  key={item}
                  className={` ${
                    activeLink === item ? "border-b-4  border-yellow-100" : ""
                  }`}
                  onClick={() => handleNavClick(item)}
                >
                  {item === "solutions" ? (
                    <div className=" relative group">
                      <NavLink
                        to="/solutions"
                        onMouseOver={handleDropDown}
                        onMouseLeave={handleDropDown}
                        className="inline-block hover:text-yellow-100"
                      >
                        Industry Solutions
                        {isDropDownOpen ? (
                          <FaChevronDown className="pl-2 inline-block" />
                        ) : (
                          <FaChevronUp className="pl-2 inline-block" />
                        )}
                      </NavLink>

                      <ul
                        ref={dropDownRef}
                        className="hidden group-hover:flex absolute flex-col bg-blue-900 p-2  rounded 10 z-10"
                      >
                        {solutions.map((item, index) => (
                          <NavLink
                            key={index}
                            // to={`/solutions#${item}`}
                            to={`/${item.trim().toLowerCase().replaceAll(' ', "")}`}
                            className="p-2 relative border-b before:content-[''] before:absolute before:top-0 before:left-0 before:h-full before:w-0 hover:text-black  hover:before:w-full before:bg-yellow-200 before:transition-all before:duration-500 before:-z-10 z-10"
                          >
                            {item}
                          </NavLink>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <NavLink
                      to={getLink(item)}
                      className={({ isActive }) =>
                        isActive
                          ? "text-base font-semibold  transition-all duration-200 hover:text-yellow-100 "
                          : "text-base  font-semibold  transition-all duration-200 hover:text-yellow-100 "
                      }
                    >
                      {capitalize(item)}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>

            <div className="w-px h-5 bg-black/20"></div>
          </div>
          <div className="demo-btn-container">
            <Link
              to="/contact"
              title=""
              className="rounded-md inline-flex items-center justify-center relative left-9 px-3 py-1.5 bg-yellow-100 text-base font-semibold text-black hover:bg-yellow-50 hover:text-black transition-all duration-200   md:relative md:left-32 md:py-2 md:px-4 lg:left-0"
              role="button"
            >
              {" "}
              Request a Demo{" "}
            </Link>
          </div>
        </div>

        <div
          ref={navbarRef}
          className={`overflow-hidden transition-all duration-100 ease-linear ${
            isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          } w-full z-50`}
          id="mobile-navbar-container"
        >
          <ul className="flex flex-col items-center font-medium mt-4 rounded-lg ">
            {header.navlist.map((item) => (
              <li
                key={item}
                className={`p-2 ${
                  activeLink === item ? "border-bottom border-blue-700" : ""
                }`}
                onClick={() => {
                  handleNavClick(item);
                  toggleMenu();
                }}
              >
                <NavLink
                  to={getLink(item)}
                  className={({ isActive }) =>
                    isActive
                      ? "text-base font-semibold  transition-all duration-200 hover:text-opacity-80"
                      : "text-base font-semibold  transition-all duration-200 hover:text-opacity-80"
                  }
                >
                  {capitalize(item)}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
