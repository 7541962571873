import medicalDevices from "../assets/images/services/analysis-2030265_1280.jpg";

import ServicePageTemplate from "../components/ServicePageTemplate";




function MedicalDevices() {
  const content = {
    title: "Polarion for Medical Devices",
    info: (
      <>
        <p>
          Accelerate medical device innovation and automate proof of compliance with Polarion ALM. Software is now the key differentiator for medical device manufacturers, offering opportunities amidst stringent quality requirements and regulatory complexities.
        </p>
        <br />
        <p><strong>Key Benefits:</strong></p>
        <ul className="list-disc list-inside">
          <li>Automated risk management aligned with ISO 14971.</li>
          <li>Full traceability throughout the lifecycle and across projects.</li>
          <li>Streamlined workflows for approvals and escalations.</li>
        </ul>
      </>
    ),
    image: medicalDevices,
  };
  
  return (
    <ServicePageTemplate 
    title={content.title}
    info={content.info}
    image={content.image}
    imageOnLeft={true}
    />
  );
}

export default MedicalDevices;
