import { createContext } from "react"
import teamcenterIcon from "../assets/icons/teamcenter-icon.png"
import matlabIcon from "../assets/icons/matlab-icon.png"
import enterpriseArchitectIcon from "../assets/icons/enterpriseArchitecture-icon.png"
import jenkinsIcon from "../assets/icons/jenkins-icon.png"
import ibmDoorsIcon from "../assets/icons/ibmDoors-icon.png"
import jiraIcon from "../assets/icons/jira-icon.png"
import azureDevOpsIcon from "../assets/icons/azureDevOps-icon.png"
import newiredIcon from "../assets/icons/newired-icon.jpeg"

//static data
const cardsData = {
  teamcenter: {
    icon: teamcenterIcon,
    title: "Teamcenter",
    tag: "Direct Integration",
    content: "Bridge the worlds of ALM and PLM with data federation and process orchestration that deliver interoperability and end-to-end traceability for complex, multi-system product development."
  },
  matlab: {
    icon: matlabIcon,
    title: "MATLAB Simulink",
    tag: "Direct Integration",
    content: "Seamlessly integrate Polarion with MATLAB and Simulink to enhance model-based systems engineering, simulation, and automatic code generation."
  },
  enterpriseArchitect: {
    icon: enterpriseArchitectIcon,
    title: "Enterprise Architect",
    tag: "Direct Integration",
    content: "Integrate Enterprise Architect with Polarion to enable unified management of requirements, architecture models, and traceability for complex systems."
  },
  jenkins: {
    icon: jenkinsIcon,
    title: "Jenkins Automation",
    tag: "Automation Integration",
    content: "Automate CI/CD pipelines with Jenkins and Polarion to ensure streamlined development workflows, continuous integration, and delivery."
  },
  ibmDoors: {
    icon: ibmDoorsIcon,
    title: "IBM DOORS",
    tag: "Direct Integration",
    content: "Leverage the power of Polarion and IBM DOORS integration to maintain bidirectional traceability of requirements, ensuring alignment across multiple platforms."
  },
  jira: {
    icon: jiraIcon,
    title: "Jira",
    tag: "Direct Integration",
    content: "Integrate Jira with Polarion to align agile project management with traceability, linking issues directly to your product development processes."
  },
  azureDevOps: {
    icon: azureDevOpsIcon,
    title: "Microsoft Azure DevOps Server (TFS)",
    tag: "Direct Integration",
    content: "Combine the power of Azure DevOps and Polarion for unified project tracking, code management, and continuous integration across teams."
  },
  newired: {
    icon: newiredIcon,
    title: "Newired",
    tag: "User Guidance Integration",
    content: "Integrate Newired with Polarion to provide seamless in-app user guidance, enhancing user experience and reducing onboarding time for complex systems."
  }
};


  const DataContext = createContext(cardsData);

function CardsProvider({children}){
    return(
        <DataContext.Provider value={cardsData}>
            {children}
        </DataContext.Provider>
    )
}


  export {DataContext, CardsProvider}